import { TuiDay, TuiDayRange, TUI_FIRST_DAY, TuiTime, HOURS_IN_DAY, tuiControlValue } from '@taiga-ui/cdk';
import { TuiDayRangePeriod } from '@taiga-ui/kit/classes';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
function tuiCreateDefaultDayRangePeriods(periodTitles = ['For all the time', 'Today', 'Yesterday', 'Current week', 'Current month', 'Previous month']) {
  const today = TuiDay.currentLocal();
  const yesterday = today.append({
    day: -1
  });
  const startOfWeek = today.append({
    day: -today.dayOfWeek()
  });
  const endOfWeek = startOfWeek.append({
    day: 6
  });
  const startOfMonth = today.append({
    day: 1 - today.day
  });
  const endOfMonth = startOfMonth.append({
    month: 1,
    day: -1
  });
  const startOfLastMonth = startOfMonth.append({
    month: -1
  });
  return [new TuiDayRangePeriod(new TuiDayRange(TUI_FIRST_DAY, today), periodTitles[0]), new TuiDayRangePeriod(new TuiDayRange(today, today), periodTitles[1]), new TuiDayRangePeriod(new TuiDayRange(yesterday, yesterday), periodTitles[2]), new TuiDayRangePeriod(new TuiDayRange(startOfWeek, endOfWeek), periodTitles[3]), new TuiDayRangePeriod(new TuiDayRange(startOfMonth, endOfMonth), periodTitles[4]), new TuiDayRangePeriod(new TuiDayRange(startOfLastMonth, startOfMonth.append({
    day: -1
  })), periodTitles[5])];
}
function tuiCreateTimePeriods(minHour = 0, maxHour = HOURS_IN_DAY, minutes = [0, 30]) {
  const timeArray = [];
  for (let i = minHour; i < maxHour; i++) {
    minutes.forEach(minute => {
      const time = new TuiTime(i, minute);
      timeArray.push(time);
    });
  }
  return timeArray;
}

/**
 * @return fractional length
 */
function tuiGetPrecision(num) {
  const [, fractionPart = ''] = String(num).split('.');
  return fractionPart.length;
}
function tuiIsFlat(items) {
  return !Array.isArray(items[0]);
}

/**
 * @internal
 */
function tuiControlValueFactory(control, valueTransformer) {
  return control ? tuiControlValue(control).pipe(map(value => valueTransformer ? valueTransformer.fromControlValue(value) : value)) : of(null);
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiControlValueFactory, tuiCreateDefaultDayRangePeriods, tuiCreateTimePeriods, tuiGetPrecision, tuiIsFlat };
